import { Stack, TextField } from '@mui/material';
import React from 'react';

export interface ZiftAddress {
  nameFirst: string;
  nameLast: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  zipExt: string;
  country: string;
  phone: string;
  email: string;
  company: string;
}

export function defaultAddress(): ZiftAddress {
  return {
    nameFirst: '',
    nameLast: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    zipExt: '',
    country: '',
    phone: '',
    email: '',
    company: '',
  }
};

export function AddressEdit({ address, setAddress }: { address: ZiftAddress, setAddress: (address: ZiftAddress) => void }) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddress(({
      ...address,
      [name]: value
    }));
  };

  return (
    <Stack gap={1}>
      <TextField value={address.nameFirst} size="small" label="First Name" name="nameFirst" onChange={handleChange} />
      <TextField value={address.nameLast} size="small" label="Last Name" name="nameLast" onChange={handleChange} />
      <TextField value={address.street1} size="small" label="Street 1" name="street1" onChange={handleChange} />
      <TextField value={address.street2} size="small" label="Street 2" name="street2" onChange={handleChange} />
      <TextField value={address.city} size="small" label="City" name="city" onChange={handleChange} />
      <TextField value={address.state} size="small" label="State" name="state" onChange={handleChange} />
      <TextField value={address.zip} size="small" label="Zip" name="zip" onChange={handleChange} />
      <TextField value={address.email} size="small" label="Zip Ext" name="zipExt" onChange={handleChange} />
      <TextField value={address.country} size="small" label="Country" name="country" onChange={handleChange} />
      <TextField value={address.phone} size="small" label="Phone" name="phone" onChange={handleChange} />
      <TextField value={address.email} size="small" label="Email" name="email" onChange={handleChange} />
      <TextField value={address.email} size="small" label="Company" name="company" onChange={handleChange} />
    </Stack>
  );
}
