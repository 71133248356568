import React, { useCallback, useMemo } from "react";
import { AgDateFilter, QuotumUsageApi, StoresApi } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { BooleanParam, DateLocalParam, StringParam, useQueryParam, useQueryParams, withDefault } from "@fifthsun/ui/api/queryParams";
import { Checkbox, Form, Select, Spin } from "antd";
import { DateLocalRangePicker } from "../../DateLocalRangePicker/index.js";
import { AgGridReact } from "ag-grid-react";
import { CellClassParams, ColDef, FirstDataRenderedEvent, GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { Link } from "react-router-dom";
import { QuotumUsageReportFilterModel } from "../AllocationTable/index.js";
import { styled } from "styled-components";

class QuotumPair implements IQuotumPair {
  shipped = 0;

  printed = 0;

  scheduled = 0;

  allocation = 0;

  add(other: IQuotumPair): QuotumPair {
    const newValue = new QuotumPair();
    newValue.shipped = this.shipped + other.shipped;
    newValue.printed = this.printed + other.printed;
    newValue.scheduled = this.scheduled + other.scheduled;
    newValue.allocation = this.allocation + other.allocation;
    return newValue;
  }
}

interface IQuotumPair {
  shipped: number;
  printed: number;
  scheduled: number;
  allocation: number;
}

interface QuotumAggregateEntry {
  dates: Map<string, QuotumPair>;
}

interface QuotumAggregateRow {
  storeId?: string;
  name: string;
  total: QuotumPair;
  dates: Record<string, QuotumPair>;
  isTotal: boolean;
}

// Styles used by the component
// NOTE: Styles must be created outside the component itself
const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

const GridWrapper = styled.div`
    flex: 1 1 auto;
  `;

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

export const AllocationReportSummary = () => {
  const now = DateTime.now().startOf('day');
  const nowString = now.setLocale("en-US").toLocaleString();

  const defaultStart = now.minus({ days: 6 }).toJSDate();
  const defaultEnd = now.plus({ days: 0 }).toJSDate();

  const [displayMode, setDisplayMode] = useQueryParam("displayMode", withDefault(StringParam, 'total'));
  const [showDenominator, setShowDenominator] = useQueryParam("showDenominator", withDefault(BooleanParam, true));

  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateLocalParam, defaultStart),
    to: withDefault(DateLocalParam, defaultEnd)
  });

  const updateFilter = (newFilter: any) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
    }));
  };

  const handleDateChange = (dates: any) => {
    updateFilter({
      from: dates?.[0] ? dates[0] : defaultStart,
      to: dates?.[1] ? dates[1] : defaultEnd
    });
  };

  const pairValueGetter = useCallback((params: ValueGetterParams<QuotumAggregateRow>): number => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const value = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    if (!value || (value.shipped === 0 && value.printed === 0 && value.scheduled === 0)) {
      return 0;
    }
    if (displayMode === 'shipped') {
      return value.shipped;
    }
    if (displayMode === 'printed') {
      return value.printed;
    }
    if (displayMode === 'scheduled') {
      return value.scheduled;
    }
    if (displayMode === 'allocation') {
      return value.allocation;
    }
    if (displayMode === 'remain_print') {
      return value.scheduled;
    }
    if (displayMode === 'remain_ship') {
      return value.scheduled + value.printed;
    }
    return value.shipped + value.printed + value.scheduled;
  }, [displayMode]);

  const pairValueFormatter = useCallback((params: ValueFormatterParams<any, number>) => {
    if (showDenominator && displayMode.startsWith('remain')) {
      // value getter needs to return a number for sorting purposes, so we have to get allocation in a roundabout way
      if (params.colDef?.field === 'total') {
        const data = params.data?.total;
        if (data?.shipped || data?.printed || data?.scheduled) {
          return `${params.value?.toLocaleString()} / ${(data?.shipped + data?.printed + data?.scheduled).toLocaleString()}`;
        }
      } else {
        const date = params.colDef?.field?.split('.')[1];
        if (date) {
          const data = params.data?.dates[date];
          if (data?.shipped || data?.printed || data?.scheduled) {
            return `${params.value?.toLocaleString()} / ${(data?.shipped + data?.printed + data?.scheduled).toLocaleString()}`;
          }
        }
      }
    }

    if (showDenominator && displayMode !== 'allocation') {
      // value getter needs to return a number for sorting purposes, so we have to get allocation in a roundabout way
      if (params.colDef?.field === 'total') {
        const data = params.data?.total;
        if (data?.allocation) {
          return `${params.value?.toLocaleString()} / ${data?.allocation.toLocaleString()}`;
        }
      } else {
        const date = params.colDef?.field?.split('.')[1];
        if (date) {
          const data = params.data?.dates[date];
          if (data?.allocation) {
            return `${params.value?.toLocaleString()} / ${data?.allocation.toLocaleString()}`;
          }
        }
      }
    }

    return params.value?.toLocaleString() ?? "";
  }, [displayMode, showDenominator]);

  const displayDates = useMemo(() => {
    const start = DateTime.fromJSDate(filter.from ?? defaultStart);
    const end = DateTime.fromJSDate(filter.to ?? defaultEnd).plus({ days: 1 });
    const length = end.diff(start, 'days').days;
    return Array.from({ length }, (_, i) => start.plus({ days: i }).toJSDate());
  }, [defaultStart, defaultEnd, filter.from, filter.to]);

  const utcFrom = useMemo(() => {
    return DateTime.fromJSDate(displayDates[0]).minus({ minutes: displayDates[0].getTimezoneOffset() }).toJSDate();
  }, [displayDates]);

  const utcTo = useMemo(() => {
    // need to sample the adjusted date for timezone offset incase we cross a DST boundary
    const adjustedDate = DateTime.fromJSDate(displayDates.at(-1)!).plus({ days: 1 }).toJSDate();
    return DateTime.fromJSDate(adjustedDate).minus({ minutes: adjustedDate.getTimezoneOffset() }).toJSDate();
  }, [displayDates]);

  const dateFilter = useMemo<AgDateFilter>(() => {
    return {
      filterType: 'date',
      condition1: {
        type: 'inRange',
        dateFrom: utcFrom.toISOString().substring(0, 10), // yyyy-mm-dd
        dateTo: utcTo.toISOString().substring(0, 10), // yyyy-mm-dd
      },
      condition2: {
        type: 'notEqual',
        dateFrom: utcTo.toISOString().substring(0, 10), // yyyy-mm-dd
      },
      operator: 'AND'
    };
  }, [utcFrom, utcTo]);

  const { data: stores, isFetching: isFetchingStores } = StoresApi.useGetStoresQuery({});

  const { data: quota, isFetching: isFetchingQuota } = QuotumUsageApi.useGetQuotumUsageQuery({
    filter: {
      date: dateFilter,
    },
    options: {
      include: ['quotum', 'store']
    }
  }, {
    refetchOnMountOrArgChange: true
  });

  const defaultColDef: ColDef<QuotumAggregateRow> = useMemo(() => ({
    sortable: true,
    width: 80,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'center', fontWeight: 'bold'
    })
  }), []);

  const columnDefs = useMemo<Array<ColDef<any>>>(() => {
    return [ {
      field: "name",
      headerName: "Customer",
      sort: "asc",
      width: 200,
      cellStyle: (_params: CellClassParams<QuotumAggregateRow>) => ({
        textAlign: 'left', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
      })
    },
    ...displayDates.map((date) => {
      const dateString = date.toLocaleDateString("en-US", { timeZone: 'utc' });
      return {
        field: `dates.${dateString}`,
        headerName: date.toLocaleDateString("en-US", { timeZone: 'utc', day: 'numeric', month: 'numeric' }),
        valueGetter: pairValueGetter,
        valueFormatter: pairValueFormatter,
        cellRenderer: DatesCellRenderer(date),
        cellStyle: DatesCellStyle(dateString, nowString)
      };
    }), {
      field: "total",
      headerName: "Total",
      valueGetter: pairValueGetter,
      valueFormatter: pairValueFormatter,
      cellRenderer: TotalCellRenderer(dateFilter),
    } ];
  }, [displayDates, nowString, pairValueFormatter, pairValueGetter, dateFilter]);

  const rowData = useMemo(() => {
    const dataMap = quota?.rows.reduce((accumulator, line) => {
      const rawDate: Date = line.date;
      const stringDate = rawDate.toLocaleDateString("en-US", { timeZone: 'utc' });
      const groupBy = line.store?.id;
      const addData = {
        shipped: line.shipped,
        printed: line.printed,
        scheduled: line.scheduled,
        allocation: line.quotum?.value ?? 0
      };
      if (groupBy) {
        let entry = accumulator.get(groupBy);
        if (!entry) {
          entry = {
            dates: new Map<string, QuotumPair>()
          };
          accumulator.set(groupBy, entry);
        }

        entry.dates.set(stringDate, (entry.dates.get(stringDate) ?? new QuotumPair()).add(addData));
      }
      return accumulator;
    }, new Map<string, QuotumAggregateEntry>());

    const storeMap = quota?.rows.reduce((accumulator, line) => {
      const storeId = line.store?.id;
      const store = stores?.rows.find((s) => s.id === storeId);
      if (store?.name && storeId && !accumulator.has(storeId)) {
        accumulator.set(storeId, store.name);
      }
      return accumulator;
    }, new Map<string, string>());

    return Array.from(dataMap?.keys() ?? []).map(AllocationReportSummaryRow(dataMap, storeMap));
  }, [quota, stores?.rows]);

  const pinnedBottomRowData = useMemo(() => {
    const dateTotals = new Map(
      displayDates.map((date) => [
        date.toLocaleDateString("en-US", { timeZone: 'utc' }),
        rowData.reduce((acc, current) =>
          acc.add(current.dates[date.toLocaleDateString("en-US", { timeZone: 'utc' })] ?? new QuotumPair()), new QuotumPair())
      ])
    );

    const rows = [];

    const row: QuotumAggregateRow = {
      name: "Total",
      total: rowData.reduce((acc, current) => acc.add(current.total), new QuotumPair()),
      dates: Object.fromEntries(dateTotals),
      isTotal: true
    };
    rows.push(row);
    return rows;
  }, [displayDates, rowData]);

  const gridOptions: GridOptions<QuotumAggregateRow> = useMemo(() => ({
    rowData,
    pinnedBottomRowData,
    defaultColDef,
    columnDefs,
    getRowStyle(params) {
      if ((params?.node?.rowPinned)) {
        if (params?.node?.rowIndex === 0) {
          return { background: '#71AF47' };
        }
        return { background: '#97C876' };
      }
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      }
      return { background: '#E2EFDA' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [rowData, pinnedBottomRowData, defaultColDef, columnDefs]);

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <Form
            size="small"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="inline"
          >
            <Form.Item label="Display Mode" style={{ minWidth: 350 }}>
              <Select
                key="display-mode"
                placeholder="Select Display Mode"
                value={displayMode}
                onChange={setDisplayMode}
                options={[
                  { value: 'total', label: 'Total' },
                  { value: 'shipped', label: 'Shipped' },
                  { value: 'printed', label: 'Printed' },
                  { value: 'scheduled', label: 'Scheduled' },
                  { value: 'remain_print', label: 'Remaining to Print' },
                  { value: 'remain_ship', label: 'Remaining to Ship' },
                  { value: 'allocation', label: 'Allocation' }
                ]}
              />
            </Form.Item>
            <Form.Item label={displayMode.startsWith('remain') ? 'Show Value / Total' : 'Show Value / Allocation'} style={{ minWidth: 400 }}>
              <Checkbox
                key="show-denominator"
                checked={showDenominator}
                onChange={(e) => setShowDenominator(e.target.checked)}
              />
            </Form.Item>
            <Form.Item label="Display Date Range">
              <DateLocalRangePicker
                placeholder={["Start", "End"]}
                allowClear={false}
                allowEmpty={[false, false]}
                value={[filter.from, filter.to] as [any, any]}
                ranges={{
                  "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
                  "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
                }}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={isFetchingQuota || isFetchingStores}>
            <AgGridReact
              className="ag-theme-madengine"
              {...gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};

function TotalCellRenderer(dateFilter: AgDateFilter) {
  return function TotalCellRendererInternal(c: ICellRendererParams<QuotumAggregateRow, number>) {
    if (!c.value) {
      return '-';
    }
    const filters: QuotumUsageReportFilterModel = {};

    filters.date = dateFilter;

    if (c.node.data?.storeId) {
      filters['store.id'] = {
        filterType: 'set',
        values: [c.node.data?.storeId]
      };
    }

    return (<Link to="/reports/allocations/details" state={filters}>{c?.valueFormatted}</Link>);
  };
}

function AllocationReportSummaryRow(dataMap: Map<string, QuotumAggregateEntry> | undefined,
  storeMap: Map<string, string> | undefined):
  (value: string, index: number, array: string[]) => QuotumAggregateRow {

  return (key) => {
    const dateEntry = dataMap?.get(key);
    const total = Array.from(dateEntry?.dates.values() ?? [])
      .reduce((acc, current) => acc.add(current), new QuotumPair());
    const row: QuotumAggregateRow = {
      storeId: key,
      name: storeMap?.get(key) ?? key,
      total,
      dates: Object.fromEntries(dateEntry?.dates ?? []),
      isTotal: false
    };
    return row;
  };
}

function DatesCellStyle(dateString: string, nowString: string):
  (params: CellClassParams<QuotumAggregateRow>) => { background: string;
  textAlign: string; border: string; fontWeight: string; } {

  return (params: CellClassParams<QuotumAggregateRow>) => {
    // if cell is over allocation, highlight in red
    let isOverAllocation = false;
    const date = params.colDef?.field?.split('.')[1];
    if (date) {
      const data = params.data?.dates[date];
      if (data?.allocation) {
        isOverAllocation = ((data?.printed ?? 0) + (data?.scheduled ?? 0)) > data?.allocation;
      }
    }

    if (dateString === nowString && !params?.node?.rowPinned) {
      return {
        background: isOverAllocation ? 'rgba(255, 0, 0, .3)' : 'rgba(0, 0, 0, .1)',
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, .1)',
        fontWeight: 'bold'
      };
    }
    return {
      background: isOverAllocation ? 'rgba(255, 0, 0, .2)' : 'rgba(0, 0, 0, 0)',
      textAlign: 'center',
      border: '1px solid rgba(0, 0, 0, .1)',
      fontWeight: 'bold'
    };
  };
}

function DatesCellRenderer(date: Date) {
  return function DatesCellRendererInternal(c: ICellRendererParams<QuotumAggregateRow, number>) {
    if (!c.value) {
      return '-';
    }

    // TO DO: should be type QuotumUsageReportFilterModel, but passing condition1 and condition2
    // to the setFilter function no longer works; it expects the conditions to be in an array as
    // below now. This is due to improvements to the AgGrid API - but it will take time to make
    // these changes to the web and core projects. So just using `any` for now.
    const filters: any = {}; 

    const dateTo = DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate();
    filters.date = {
      filterType: 'date',
      conditions: [{
        type: 'inRange',
        dateFrom: date.toISOString().substring(0, 10), // yyyy-mm-dd
        dateTo: dateTo.toISOString().substring(0, 10), // yyyy-mm-dd
      },
      {
        type: 'notEqual',
        dateFrom: dateTo.toISOString().substring(0, 10), // yyyy-mm-dd
      }],
      operator: 'AND'
    };

    if (c.node.data?.storeId) {
      filters['store.id'] = {
        filterType: 'set',
        values: [c.node.data?.storeId]
      };
    }

    return (<Link to="/reports/allocations/details" state={filters}>{c?.valueFormatted}</Link>);
  };
}
